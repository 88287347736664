import React from 'react';
import './App.css';
import HeroBackground from './MarkTwain.jpg';

function App() {

  return (
    <main>
      <img src={HeroBackground} alt="nack" className="img-back"/>
    </main>
  );
}

export default App;
